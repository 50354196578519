import React, { Component } from 'react'
import axios from 'axios'
import Alert from 'react-bootstrap/Alert'

import '../styles/quiz.css'
import QuizCard from './QuizCard'

class Quiz extends Component {
	state = {
		success: [],
		errors: [],
		questionIndex: 0
	}

	componentDidMount() {
		this.getQuiz()
	}

	render() {
		const { success, errors, training, quiz, questionIndex, quizResult } = this.state

		return (
			<div>
				{success.map((s, i) => (
					<Alert className={'d-flex justify-content-between'} key={i} variant={'success'}>
						<span>{s}</span>
						<span className='pointer' onClick={() => this.setState({ success: [] })}>x</span>
					</Alert>
				))}

				{errors.map((e, i) => (
					<Alert className={'d-flex justify-content-between'} key={i} variant={'danger'}>
						<span>{e}</span>
						<span className='pointer' onClick={() => this.setState({ errors: [] })}>x</span>
					</Alert>
				))}
				<div className='quiz-container'>
					{quiz &&
						<QuizCard
							name={training.name}
							quiz={quiz}
							questionIndex={questionIndex}
							onNext={this.handleNext}
							onPrev={this.handlePrev}
							onSelectAnswer={this.handleSelectAnswer}
							onSubmit={this.handleSubmit}
							minScore={quiz.min_score}
							quizResult={quizResult}
							onRetakeQuiz={this.handleRetakeQuiz}
						/>
					}
				</div>
			</div>
		)
	}

	handleSuccess = () => {
		window.scrollTo(0, 0)

		this.setState({
			success: this.state.success.concat(['Successful!'])
		})
	}

	handleError = (e) => {
		window.scrollTo(0, 0)

		this.setState({
			errors: this.state.errors.includes(e.response.data.message) ? [...this.state.errors] : this.state.errors.concat([`${e.response.data.message}`])
		})
	}

	getQuiz = async () => {
		const trainingPlanId = localStorage.getItem('trainingPlanId')

		try {
			const training = await axios.get(`https://api.fieldday.app/coreAPI/training/plan?trainingPlanId=${trainingPlanId}`)

			this.setState({
				training: training.data,
				quiz: training.data.quiz
			})
		} catch(e) {
			this.handleError(e)
		}
	}

	handleNext = () => {
		const { questionIndex } = this.state

		this.setState({
			questionIndex: questionIndex + 1
		})
	}

	handlePrev = () => {
		const { questionIndex } = this.state

		this.setState({
			questionIndex: questionIndex - 1
		})
	}

	handleSelectAnswer = (questionIndex, answerIndex, event) => {
		const questionKey = this.state.quiz.question_key
		const newQuestionKey = [...this.state.quiz.question_key]
		const userAnswers = questionKey[questionIndex].userAnswers ? [...questionKey[questionIndex].userAnswers] : []

		if(questionKey[questionIndex].userAnswers) {
			if(event.target.type === 'radio') {
				userAnswers[0] = questionKey[questionIndex].answers[answerIndex]
			} else if(!userAnswers.includes(questionKey[questionIndex].answers[answerIndex]) && event.target.type !== 'radio') {
				userAnswers.push(questionKey[questionIndex].answers[answerIndex])
			} else {
				userAnswers.splice(userAnswers.indexOf(questionKey[questionIndex].answers[answerIndex]), 1)
			}
		}

		if(!questionKey[questionIndex].userAnswers) {
			userAnswers.push(questionKey[questionIndex].answers[answerIndex])
		}

		newQuestionKey[questionIndex].userAnswers = userAnswers

		this.setState({
			quiz: {
				...this.state.quiz,
				question_key: newQuestionKey
			}
		})
	}

	handleSubmit = async () => {
		const trainingPlanId = localStorage.getItem('trainingPlanId')
		const ambassadorId = localStorage.getItem('ambassadorId')
		const token = localStorage.getItem('token')
		const questionKey = this.state.quiz.question_key

		try {
			const response = await axios.post(`https://api.fieldday.app/coreAPI/training/quiz`,{
				ambassador_answers: questionKey.map(q => ({
					question: q.question,
					answers: q.userAnswers
				}))
			}, {
				params: {
					trainingPlanId,
					ambassadorId,
					token
				}
			})

			this.setState({
				quizResult: response.data
			})
		} catch(e) {
			console.error(e)
			this.handleError(e)
		}
	}

	handleRetakeQuiz = () => {
		this.setState({
			questionIndex: 0,
			quizResult: null,
			quiz: {
				...this.state.quiz,
				question_key: this.state.quiz.question_key.map(q => ({
					...q,
					userAnswers: []
				}))
			}
		})
	}
}

export default Quiz
