import React, { Component } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import Container from 'react-bootstrap/Container'
import Navbar from 'react-bootstrap/Navbar'
import Col from 'react-bootstrap/Col'

import logo from '../logo.png'
import '../styles/App.css'
import CoverPage from './CoverPage'
import Training from './Training'
import Quiz from './Quiz'

class App extends Component {
  state = {}

  componentDidMount() {
    const params = new URLSearchParams(window.location.search)
    const trainingPlanId = params.get('trainingPlanId')
    const ambassadorId = params.get('ambassadorId')
    const token = params.get('token')

    if(trainingPlanId) {
      localStorage.setItem('trainingPlanId', trainingPlanId)
      localStorage.setItem('ambassadorId', ambassadorId)
      localStorage.setItem('token', token)
    }
  }

  render() {
    return (
      <Router>
        <Navbar bg="dark" variant="dark">
          <Navbar.Brand>
            <Col lg={2} md={5} xs={5} className='logo-wrapper'>
              <img src={logo} alt='' />
            </Col>
          </Navbar.Brand>
        </Navbar>
        <Container className='App'>
          <Switch>
            <Route exact path='/' component={CoverPage} />
            <Route path='/training' component={Training} />
            <Route path='/quiz' component={Quiz} />
            <Route component={() => <h1>Not Found</h1>} />
          </Switch>
        </Container>
      </Router>
    );
  }
}

export default App;
