import React, { Component, Fragment } from 'react'
import { Link } from 'react-router-dom'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'

class QuizCard extends Component {
	state = {}

	render() {
		const { name, quiz, questionIndex, onNext, onPrev, onSelectAnswer, onSubmit, quizResult, onRetakeQuiz } = this.props
		const question = quiz.question_key[questionIndex].question
		const answers = quiz.question_key[questionIndex].answers
		const answerKey = quiz.answer_key
		const total = quiz.question_key.length
		const userAnswers = quiz.question_key[questionIndex].userAnswers || []
		const minScore = quiz.min_score

		return (
			<Col lg={8} className='m-auto' key={questionIndex + Math.random()}>
				<Card className='quiz-card pl-2 pr-2'>
					<Card.Header className='bg-white d-flex justify-content-between'>
						<span>{name}</span>
						<span>{questionIndex + 1}/{total}</span>
					</Card.Header>
					<Card.Body>
						{quizResult
							? <QuizResult
									quizResult={quizResult.quiz_results}
									minScore={minScore}
								/>
							: <QuizBody
									question={question}
									answers={answers}
									onSelectAnswer={onSelectAnswer}
									questionIndex={questionIndex}
									userAnswers={userAnswers}
									answerKey={answerKey}
								/>
						}
					</Card.Body>
					<Card.Footer className='bg-white d-flex justify-content-between'>
						<div>Minimum Score: {minScore}%</div>
						{quizResult
							? <ResultFooter
									quizResult={quizResult.quiz_results}
									minScore={minScore}
									onRetakeQuiz={onRetakeQuiz}
								/>
							: <QuizFooter
									questionIndex={questionIndex}
									total={total}
									onPrev={onPrev}
									onNext={onNext}
									onSubmit={onSubmit}
									userAnswers={userAnswers}
								/>
						}
					</Card.Footer>
				</Card>
			</Col>
		)
	}
}

function QuizBody({ questionIndex, question, answers, onSelectAnswer, userAnswers, answerKey }) {
	return (
		<Fragment>
			<Card.Title>{question}</Card.Title>
			{answers.map((answer, i) => (
				<Card.Text key={i}>
					<label className='checkmark-container'>
						<input
							// name={name}
							// type={'checkbox'}
							type={answerKey[questionIndex].answers.length > 1 ? 'checkbox' : 'radio'}
							checked={userAnswers.includes(answer)}
							onChange={e => onSelectAnswer(questionIndex, i, e)}
						/>
						<span className="checkmark"></span>
						{answer}
					</label>
				</Card.Text>
			))}
		</Fragment>
	)
}

function QuizFooter ({ questionIndex, total, onPrev, onNext, onSubmit, userAnswers }) {
	return (
		<div>
			{questionIndex > 0 &&
				<Button className='mr-3' variant='outline-dark' onClick={onPrev}>Back</Button>
			}
			{questionIndex < total - 1
				? <Button disabled={!userAnswers.length} variant='info' onClick={onNext}>Next</Button>
				: <Button disabled={!userAnswers.length} variant='success' onClick={onSubmit}>Submit</Button>
			}
		</div>
	)
}

function QuizResult ({ quizResult, minScore }) {
	return (
		<div className='text-center'>
			{quizResult[quizResult.length - 1].score >= minScore
				? <Fragment>
						<Card.Title>Score: {quizResult[quizResult.length - 1].score}%</Card.Title>
						<Card.Title>Congratulations!</Card.Title>
						<Card.Text>
							You passed the quiz and are now eligible to start representing this brand!
							Please make sure you’ve applied to the gigs you are available to work and communicate
							with your account representative to ensure we set you up on the right campaigns.
						</Card.Text>
					</Fragment>
				: <Fragment>
						<Card.Title>Score: {quizResult[quizResult.length - 1].score}%</Card.Title>
						<Card.Title>You Can Try Again!</Card.Title>
						<Card.Text>
							You’re almost there! Unfortunately, it looks like you missed too many questions.
							Please review the training material again and then try retaking the quiz.
						</Card.Text>
					</Fragment>
			}
		</div>
	)
}

function ResultFooter ({ quizResult, minScore, onRetakeQuiz }) {
	return (
		<div>
			{quizResult[quizResult.length - 1].score >= minScore
				? <Link to='/'><Button variant='info'>Finish</Button></Link>
				: <div>
						<Button className='mr-3' variant='outline-dark' onClick={onRetakeQuiz}>Retake Quiz</Button>
						<Link to='/'><Button>Training</Button></Link>
					</div>
			}
		</div>
	)
}

export default QuizCard
