import React from 'react'

import logo from '../spinner-logo.png'

function Loading(loading) {
	// if(!loading) return null

	return (
		<div className="loading-logo-background">
			<div className="loading-logo">
				<img src={logo} alt='' />
			</div>
		</div>
	)
}
export default Loading
