import React, { Component } from 'react'
import ReactPlayer from 'react-player'

class Video extends Component {
	state = {
		duration: null,
		timeOnPage: 0,
		videoEnded: false
	}

	componentWillUnmount() {
		clearInterval(this.state.intervalID)
	}

	render() {
		const { video } = this.props

		return (
			<div className='video-player-container'>
				<ReactPlayer
					url={video.url}
					width={'100%'}
					height={'100%'}
					className='react-player'
					onEnded={() => this.setState({ videoEnded: true })}
					onDuration={(duration) => this.setState({ duration })}
					onPlay={() => this.startTimer()}
					onPause={() => clearInterval(this.state.intervalID)}
					controls
					playing
				/>
			</div>
		)
	}

	startTimer = () => {
		const intervalID = setInterval(() => this.setState({
			timeOnPage: this.state.timeOnPage + 1,
			intervalID: intervalID
		}, () => {
			const obj = {
				timeOnPage: this.state.timeOnPage,
				videoDuration: this.state.duration,
				watched: this.didWatch()
			}

			localStorage.setItem(this.props.video.url, JSON.stringify(obj))
		}),
		1000)
	}

	didWatch = () => {
		if(this.state.timeOnPage >= this.state.duration - 7) return true

		if(JSON.parse(localStorage.getItem(this.props.video.url)) && JSON.parse(localStorage.getItem(this.props.video.url)).watched) return true

		return false
	}
}

export default Video
