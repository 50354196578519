import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Button from 'react-bootstrap/Button'

import '../styles/coverPage.css'

class CoverPage extends Component {
	render() {
		return (
			<div className='cover-page text-center'>
				<div className='background-logo'></div>
				<h1>Welcome To Field Day Training</h1>
				<hr />
				<p style={{ color: 'black', fontWeight: '500' }}>
					Are you ready to become a brand expert?
					If you’ve made it this far, we are excited to have you in our network and the opportunity to work
					with you on this and hopefully many of our brand partners.
				</p>
				<p style={{ color: 'black', fontWeight: '500' }}>
					It is very important to be knowledgeable and professional on both the brand and campaign you are applying to work with.
					In order to be eligible to work with any brand, you must review and complete the following training.
					This includes reviewing all presentations and documents, watching each video, and passing the quiz with a score over the passing score. 
				</p>
				<p style={{ color: 'black', fontWeight: '500' }}>
					How do we know if you’ve opened the materials or watched the videos?
					A green check mark will appear next to each step. You must have a green check mark next to every line to take the quiz.
				</p>
				<p style={{ color: 'black', fontWeight: '500' }}>
					If you fail the quiz, you can always review the training and try again. 
				</p>
				<p style={{ color: 'black', fontWeight: '500' }}>
					Good luck and looking forward to working with you!
				</p>
				<Link to='/training'>
					<Button size='lg' variant ='dark'>START</Button>
				</Link>
			</div>
		)
	}
}

export default CoverPage
